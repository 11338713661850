import React from 'react';
import { graphql } from 'gatsby';
import { BlogPost } from 'components';

export default ({ data }: any) => <BlogPost data={data} />;

export const query = graphql`
  query post($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPost(id: { eq: $id }) {
      title
      content
      commentCount
      date(formatString: "DD  MMM YYYY")
      author {
        node {
          name
          description
          avatar {
            url
          }
        }
      }
      categories {
        nodes {
          name
          uri
        }
      }
      tags {
        nodes {
          name
          uri
        }
      }
      comments {
        nodes {
          date(formatString: "LLL")
          content
          author {
            node {
              name
              avatar {
                url
              }
            }
          }
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    nextPage: wpPost(id: { eq: $nextPage }) {
      title
      uri
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(maxWidth: 150, maxHeight: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    previousPage: wpPost(id: { eq: $previousPage }) {
      title
      uri
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(maxWidth: 150, maxHeight: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    AuthorSvg: file(name: { eq: "author" }) {
      id
      publicURL
      name
    }
    CalenderSvg: file(name: { eq: "calendar" }) {
      id
      publicURL
      name
    }
  }
`;
